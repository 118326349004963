.Example {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: rgb(var(--color-storm-grey));
}

.Example a {
  color: inherit;
}
@media screen and (max-width: calc(theme(screens.lg) - 0.05px)) {
  .Example {
    display: none;
  }
  .WithQuery {
    display: none;
  }
}
