.NavBar {
  top: 0;
  left: 0;
  right: 0;
  height: var(--navbar-height);
  transition:
    transform 0.2s,
    background-color 0.2s;
  z-index: 49;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  background-color: white;
  backdrop-filter: saturate(180%) blur(20px);
  @apply bg-white;
}

@media screen and (max-width: calc(theme(screens.lg) - 0.05px)) {
  .HideNavBar {
    transform: translateY(-64px);
  }
}

.Blue {
  background-color: rgb(var(--color-electric-blue));
}

.ForegroundWhite {
  &:not(.BlurNavBar) {
    color: white;
    backdrop-filter: unset;

    & :global(.button) {
      color: white !important;
      background: transparent !important;
      transition: none !important;

      &:hover {
        @apply !bg-dark-blue/10;
      }
    }

    .Logo path {
      fill: white !important;
    }
  }
}

.Transparent {
  background-color: rgba(255, 255, 255, min(calc(var(--scroll-top) / 600), 0.85));
  box-shadow: none;
}

.BlurNavBar {
  background-color: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 10px 27px rgb(26 28 43 / 10%);
}

@supports (background-color: rgba(255, 255, 255, min(calc(var(--scroll-top) / 600), 0.85))) {
  .BlurNavBar.Transparent {
    background-color: rgba(255, 255, 255, min(calc(var(--scroll-top) / 600), 0.85)) !important;
  }
  .Transparent {
    backdrop-filter: saturate(min(calc(var(--scroll-top)) * 1% + 100%, 180%))
      blur(calc(min(var(--scroll-top), 20) * 1px)) !important;
  }
}

.Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0 20px;
}
@media screen and (max-width: calc(theme(screens.lg) - 0.05px)) {
  .Container {
    padding: 0 16px;
  }
}

.DrawerContent {
  display: flex;
  flex-direction: column;
}

.DrawerContent > a {
  padding: 8px 24px;
  display: block;
  font-size: 18px;
}
.DrawerContent > a::after {
  display: none;
}
.CloseDrawer {
  text-align: right;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 8px;
}
