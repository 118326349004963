.Wrapper .Item {
  background: rgba(0, 0, 0, 0.4);
  color: white;
  height: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding: theme(spacing.2);
  transition: background 0.3s;
}
.Wrapper.Small .Item {
  height: 104px !important;
  min-height: 104px !important;
  align-items: flex-start;
  justify-content: flex-end;
}

.Small :global(.typo-h3) {
  @apply text-base !mb-0 !text-left leading-none;
}

.Count {
  @apply mt-2;

  .Small & {
    @apply !mt-0 text-sm;
  }
}

.Wrapper .Item:hover {
  background: rgba(0, 0, 0, 0.6);
}

.Wrapper {
  text-decoration: none;
  z-index: 0;
}

@media screen(md) {
  .Wrapper .Item {
    min-height: 281px;
  }
}
