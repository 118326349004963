.Distances {
  padding: 0;
  list-style-type: none;
  margin: 0 -6px;
}
.Distances li {
  display: inline-block;
  margin: 8px 6px;
}
.Distances a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
  background: white;
  padding: 4px 6px;
  border-radius: 2px;
  font-size: 14px;
  box-shadow:
    7px 7px 15px #ececed,
    -7px -7px 15px #fafafb;
  transition:
    box-shadow 0.2s,
    color 0.2s;
  font-weight: 600;
}
.Distances a:hover {
  box-shadow:
    6px 6px 12px #dddde4,
    -6px -6px 12px #fafafb;

  color: inherit;
}
