.Logo {
  height: 22px;
}

.Registered {
  margin-left: 8px;
}

.SectionTitle {
  font-weight: 700;
  margin-top: 0;
}

.Link {
  margin-bottom: 8px !important;
  display: block;
  text-decoration: none !important;
  cursor: pointer;
}

.Link:hover {
  text-decoration: underline !important;
}

.Link::before {
  content: "";
  display: inline-block;
  border-top-right-radius: 3px;
  border-top: 2px solid rgb(var(--color-electric-blue));
  border-right: 2px solid rgb(var(--color-electric-blue));
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
  margin-right: 6px;
}

.Link:hover::before {
  border-top: 2px solid rgb(var(--color-orange));
  border-right: 2px solid rgb(var(--color-orange));
}

.Copyright {
  color: #6b7490;
  text-align: center;
  font-size: 14px;
}

@media screen and (max-width: calc(theme(screens.sm) - 0.05px)) {
  .SectionTitle {
    margin-bottom: 5px;
  }
  .Link {
    margin-bottom: 3px !important;
    margin-left: 6px !important;
  }
}

.LocaleSwitcher select {
  background-color: transparent;
}

.LocaleSwitcher option {
  @apply bg-dark-blue;
}
