.Disciplines {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -8px;
  gap: 20px 10px;
}

.DisciplineButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* https://neumorphism.io/#f3f3f4 */
  background: #fff;
  box-shadow:
    7px 7px 15px #ececed,
    -7px -7px 15px #fafafb;

  border-radius: 2px;
  color: inherit;
  text-decoration: none;
  padding: 0.5rem;
  transition:
    box-shadow 0.2s,
    background 0.2s;
}

.DisciplineButton:hover {
  text-decoration: none;
}

.DisciplineButton:hover {
  box-shadow:
    6px 6px 12px #dddde4,
    -6px -6px 12px #fafafb;
}

.DisciplineEmoji {
  margin-bottom: 8px;
}

.DisciplineButtonLabel {
  font-weight: 700;
  font-size: 12px;
  line-height: 90%;
  text-transform: uppercase;
  text-align: center;
}

@media screen and (max-width: calc(theme(screens.lg) - 0.05px)) {
  .Disciplines {
    display: block;
  }

  .DisciplineButton {
    padding: 6px 8px;
    margin: 0 6px;
  }
  .DisciplineEmoji {
    margin-right: 4px;
    margin-bottom: 0;
    display: flex;
  }
  .DisciplineEmoji > div {
    width: 16px !important;
    height: 16px !important;
  }
}
