.Highlight {
  background: #f3f3f4;
  box-shadow:
    3px 3px 7px #e4e4e5,
    -3px -3px 7px #ffffff;
  margin-left: 5px;
  border-radius: 12px;
  display: inline-block;
}

.LocationResult {
  display: flex;
  flex-direction: row;
  color: inherit;
  text-decoration: none;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 5px;
}

.LocationIcon {
  padding: 5px;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.LocationName {
  flex-grow: 1;
  font-size: 16px;
  padding-left: 12px;
}
