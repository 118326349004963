@keyframes backInDown {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes backOutUp {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }

  100% {
    transform: translateY(-50px);
    opacity: 0;
  }
}

@keyframes hiddenDelay {
  0% {
    display: block;
  }

  100% {
    display: none;
  }
}

.Wrapper {
  overflow: hidden;
  display: block;
  height: 100%;
}

.Wrapper.Hidden {
  /* pointer-events: none; */
  display: none;
}

.Container {
  display: flex;
  flex-direction: column;
  height: 100%;

  animation-name: backInDown;
  animation-duration: 0.3s;
  animation-fill-mode: both;
}

.Hidden .Container {
  transition: opacity 2s;
  opacity: 0;
  animation-name: backOutUp;
}

.Header {
  padding-bottom: 24px;
}

.MainContent {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  margin: 0 calc(-1.5 * theme(spacing.1));
}

.ContentColumn {
  display: flex;
  flex-direction: column;
  margin: 0 calc(1.5 * theme(spacing.1));
  flex: 0 1 100%;
  width: 33.3333%;
}

.SectionContent {
  position: relative;
  flex-grow: 1;
}

.SectionDisciplines .SectionContent {
  margin-bottom: 32px;
}

.ScrollContainer {
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  scrollbar-width: thin;
  scrollbar-color: #e0e1e2 transparent;
}

.Wrapper ::-webkit-scrollbar {
  width: 12px;
}

.Wrapper ::-webkit-scrollbar-track {
  background: transparent;
}

.Wrapper ::-webkit-scrollbar-thumb {
  background-color: #e0e1e2;
  border-radius: 20px;
  border: 4px solid #f1f3f4;
}

@media screen and (max-width: calc(theme(screens.lg) - 0.05px)) {
  .Container {
    /* padding: 16px; */
    border-radius: 0;
    overflow-y: visible;
    -webkit-overflow-scrolling: touch;
  }
  .Wrapper {
    overflow: visible;
    height: auto;
  }
  .ContentColumn {
    margin: 0;
    width: 100%;
  }
  .Header {
    padding-bottom: 10px;
  }
  .MainContent {
    flex-direction: column;
    flex: 0 0 auto;
    margin: 0;
  }
  .ContentColumn {
    margin: 0;
    width: 100%;
  }
  .SectionDisciplines .SectionContent,
  .SectionDistances .SectionContent,
  .ScrollContainer {
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 8px;
    margin-left: -16px;
    margin-right: -16px;
    padding: 0 16px;
  }

  .SectionDistances,
  .SectionDisciplines,
  .SectionLocations {
    white-space: nowrap;
  }

  .SectionDisciplines .SectionContent,
  .SectionTags .ScrollContainer > div:first-child {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    white-space: nowrap;
    max-width: none;
  }

  .SectionTags .ScrollContainer a > div:first-child {
    display: none;
  }

  .SectionTags .ScrollContainer a > div:last-child {
    height: auto !important;
    min-height: auto !important;
    background: white;
    color: rgb(var(--color-dark-blue));
    padding: 5px 8px;
    box-shadow:
      7px 7px 15px #ececed,
      -7px -7px 15px #fafafb;
  }
  .SectionTags .ScrollContainer a > div:last-child > div:first-child {
    text-shadow: none !important;
    font-size: 14px;
    font-weight: 600;
  }
  .SectionTags .ScrollContainer a > div:last-child > div:last-child {
    display: none;
  }

  .SectionContent {
    margin-bottom: 12px;
  }
  .SectionDisciplines .SectionContent,
  .SectionDistances .SectionContent {
    margin-bottom: 12px;
  }
  .SectionDistances .SectionContent li {
    margin-bottom: 0;
    margin-top: 0;
  }

  .SectionDisciplines .SectionContent > div > a {
    padding: 5px 8px;
    flex-direction: row;
    height: auto;
    width: auto;
    flex: 0 0;
  }
  .SectionDisciplines .SectionContent > div > a > svg {
    font-size: 16px;
    margin-top: 0;
    margin-right: 8px;
  }

  .RacesColumn {
    order: 2;
  }
  .DistanceAndDisciplineColumn {
    order: 1;
  }
  .SectionLocations {
    order: 4;
  }
  .SectionTags {
    order: 1;
  }

  .SectionDisciplines .SectionContent > div > a,
  .SectionTags .ScrollContainer > div > div > a > div:last-child,
  .SectionDistances .SectionContent > ul > li > a {
    @apply border border-storm-grey !h-8 inline-flex justify-center items-center py-0 px-2;
    box-shadow: none;
  }
}

@media screen and (max-width: calc(theme(screens.sm) - 0.05px)) {
  .SectionDisciplines .SectionContent,
  .SectionDistances .SectionContent,
  .ScrollContainer {
    scrollbar-color: transparent transparent;
  }

  .SectionDisciplines .SectionContent::-webkit-scrollbar-thumb,
  .SectionDistances .SectionContent::-webkit-scrollbar-thumb,
  .ScrollContainer::-webkit-scrollbar-thumb {
    display: none;
    background-color: transparent;
  }
}
