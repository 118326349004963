.Event {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  color: inherit;
  text-decoration: none;
}

.Event :global(.MuiSkeleton-root) {
  border-radius: 4px;
}

.Picture,
.Image,
.Image img {
  width: 80px;
  height: 56px;
}

.Image :global(.MuiSkeleton-root) {
  border-radius: 8px;
}

.EventText {
  flex-grow: 1;
  padding-left: 12px;
}

.EventLocation {
  font-size: 12px;
  font-weight: 600;
  margin-top: 6px;
  @apply text-storm-grey;
}

.EventLocation > :first-child {
  @apply text-electric-blue;
  font-size: 135%;
  vertical-align: sub;
  margin-right: 4px;
}
