.SocialLinks {
  text-align: center;
  margin: 60px 0;
}
.SocialLinks a {
  color: #6b7490;
  text-decoration: none;
  margin: 0 20px;
  transition: color 0.2s;
}
.SocialLinks a:hover {
  color: white;
}
.SocialLinks svg {
  width: 32px;
  height: 32px;
}

@media screen and (max-width: calc(theme(screens.sm) - 0.05px)) {
  .SocialLinks a {
    margin: 0 10px;
  }

  .SocialLinks svg {
    width: 26px;
    height: 26px;
  }
}
