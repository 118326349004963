.ImageWithContent {
  display: block;
  position: relative;
  z-index: 0;
}

.PictureWrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.Picture {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: all 0.5s;
}

.Content {
  height: 100%;
}

.WithSkeleton {
  & > div {
    opacity: 0;
  }

  .Skeleton,
  :global(.react-loading-skeleton) {
    opacity: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
